<template>
  <div class="contact">
    <div v-if="!isOpen" class="contact-activation" @click="togglecontact">
      <i class="far fa-envelope"></i>
    </div>

    <div v-if="isOpen" class="contact-container">
      <div class="contact-header">
        <div class="contact-info">
          <span class="contact-title">Fale conosco</span>
        </div>
        <div @click="togglecontact" class="close-contact">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="contact-internal-container">
        <div class="contact-saldation">
          <p>Olá, {{ this.name }}</p>
          <p>{{ this.email }}</p>
        </div>
        <div class="row">
          <Input v-model="form.phone" label="WhatsApp" mask="(##) #####-####" />
          <div class="dropdown_form col-12">
            <label>Mensagem</label>
            <textarea
              v-model="form.text"
              rows="6"
              class="input_text"
            ></textarea>
          </div>
          <div class="col-12 send">
            <button
              :disabled="loading"
              type="button"
              class="button_outline"
              @click.prevent.stop="submit()"
            >
              {{ loading ? 'Enviando' : 'Enviar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '../input.vue'
import swal from 'sweetalert'

const defaultForm = { text: '', phone: '' }

export default {
  data: () => ({
    isOpen: false,
    form: { ...defaultForm },
    loading: false,
    name: 'teee',
    email: '44564654'
  }),
  components: {
    Input
  },
  methods: {
    async submit () {
      const url = 'contact'
      this.loading = true
      try {
        await this.$http.post(url, this.form)
        swal({
          title: 'Sucesso!',
          icon: 'success',
          text: 'Mensagem enviada com sucesso..',
          buttons: false,
          timer: 2000
        })
        this.form = { ...defaultForm }
        this.isOpen = false
      } catch (error) {
        swal({
          title: 'Error!',
          icon: 'error',
          text: 'Erro ao enviar mensagem.',
          buttons: false,
          timer: 3000
        })
      } finally {
        this.loading = false
      }
    },
    togglecontact () {
      this.isOpen = !this.isOpen
    }
  },
  mounted () {
    const localUser = localStorage.getItem('user')
    const user = JSON.parse(localUser)
    this.name = user.name
    this.email = user.email
  }
}
</script>

<style scoped lang="scss">
.contact {
  position: fixed;
  bottom: 2.5rem;
  left: 2.5rem;
}

.contact-container {
  width: 380px;
  height: 540px;
  background: #fff;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  overflow: hidden;
}

.contact-activation {
  width: 60px;
  height: 60px;
  color: #f6f6f6;
  background-color: #47be71;
  border-radius: 100%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
}

.contact-saldation {
  padding: 0 8px;
}

.contact-activation:hover {
  background-color: darken(#47be71, 5%);
}

.contact-header {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
}

.contact-info {
  width: calc(100% - 110px);
  display: flex;
  flex-direction: column;
}

.contact-title {
  color: #5c575c;
  font-size: 20px;
  font-weight: bold;
}

.contact-subtitle {
  color: #939393;
  font-size: 12px;
}

.send {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.send button {
  width: 100%;
}

.close-contact {
  padding: 1rem;
  border-radius: 100%;
  font-size: 30px;
  font-weight: 400;
  cursor: pointer;
}

.contact-internal-container {
  padding: 0 1.5rem 0 1.5rem;
}

.row {
  margin-top: 2rem;
}

.dropdown_form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.input_text {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  border-radius: 0.5rem;
}
</style>
